<template>
  <div>
    <div class="header">
      <div class="header_content">
        <div v-if="!hasToken" @click="toLogin('/Register')">{{$t("auth.register")}}</div>
        <div v-else></div>
        <div class="img">
          <img src="/img/homenavlogo.png" alt="" />
        </div>
        <div style="display: flex; align-items: center;" v-if="!hasToken" @click="toLogin('/Login')">
          <van-icon name="chat-o" color="#fff" size="24" />
          {{$t("auth.login")}}
        </div>
        <div v-else @click="toLogin('/ServiceOnline')">
          <van-icon name="chat-o" color="#fff" size="24" />
        </div>
      </div>
    </div>
    <div style="height: 160px;">
      <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v,key) in banners" :key="key">
           
            <van-image class="banner_img"  :src="`https://${v.url}`" width="100%" height="160px">
              <template v-slot:loading>
                <van-loading type="circular"/>
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
    </div>
    <div style="padding: 8px; background: rgb(243, 243, 245);">
      <van-notice-bar
        class="notice-swiper"
        color="rgb(62, 30, 136)"
        background="#fff"
        scrollable
        :text="this.notice"
      >
        <template #left-icon>
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAkCAYAAAD2IghRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC60lEQVRYhdXYXahUVRQH8N/dXft4CBLKS4QgPWReEx81UXtIoxAiKTJIMX0tjdCLkGZZBCqXKIIgEgtKCQIhiqLSlz6M9CEI4oaUglIvUSiIqNQ5Paw5cBzHO2fmnmnm/mGYs9dea+//2WfvtdZeQ2Prf5XkhvKs8Z9LMinPDcmlPJ6T7Iq+1NCP/queZ6U8G0t5tirJ85Rnh1KevdBavxizmLMsC53gUeaQG1Y/nsEruKUkG8W9WFHXJKmugbAY3+BNV5IucL+pEV+G24tGHcRvxhv4Hkvb6I52Mf5KHMXX+BkLmTrxtZjA5or6/3Qw9nx8hC/FNoOZ4kW63uNLsR0PdmiXVdC5DTu0XozDeJ/WxEdxD0aQN37/4ibMxhIs6pBwFQxjK7a5+oycwIv4sKxcYCHG1XjyO8B67MSdTfLzeBV7NX2tgvgKfNVrdtfAOLa0kL8lSP/RymhYeIXPe8erLR5van+MXfhxMqMkDkEvAlFVPIqf8AMexiPakCYIP9RTWu1xXMM3d4KE6+vn0nsk4eqmHZLw09MOdSZZ/yv66U3K2IgFFXUvYN8gEH8Wr3dos3EQtsqtXdiMDMKK7xFnrcjVJ8sgZ+Ac3h4E4udFitwRBmGrdIVpTfy6fpPoBgmX+k2iGySc6jOHO0QOfkiUOCoh4WCvGFXEeyIPXy1KHPsxp51Rwhf4pIfE2uFEU3uDKHm8hBuuZVR4lcdEFaofeBpj+Ksku1Hc6n/BU62MCuKXsVy85e+9YjgJxnEXXmuSz8G7+A4PlDuaI+cu7BYFn9nizYsQnIkXHcFcPKFeV/q3uO3vE6u9ptS3RGzpA+ILnWsV8i/hSIWJduI5UZ2tiqEKOhNiUd5pzLG81PckTuP5qUTOk9gkqlqHK9p0cr89gvsE2YmS/Cj1hPxjohC5TvuYcKaL8Q+KzHGtKDV/Sr25yge4Gy9rfQH/DZ9NYfwD+LZo1J1kXRYHa15jorP4U6zaMlysa6L/AAjlvYZkAVJOAAAAAElFTkSuQmCC"
            alt="Custom Icon"
            style="width: 15px; height: 15px; margin-right: 10px;"
          />
        </template>
      </van-notice-bar>
    </div>
    <div>
      <div style="margin: 10px;">
        <span style="font-size: 16px;">  {{$t("index.Highend_area")}}</span>
        <span class="sub">{{$t("index.extreme")}}</span>
      </div>
      <div
        style="
          display: flex;
          width: 100%;
          overflow-y: auto;
          flex-wrap: nowrap;
          padding: 10px;
        "
      >
        <div
          class="gdgride"
          style="margin-right: 5px;"
          v-for="(group, index) in groupedData"
          :key="index"
        >
          <div v-for="item in group" :key="item.id">
            <div
              @click="toDetail(item.id)"
              class="van-image"
              style="
                width: 100px;
                height: 100px;
                overflow: hidden;
                border-radius: 8px;
              "
            >
              <img
                :src="item.img_url"
                class="van-image__img"
                style="object-fit: cover;"
              />
            </div>
            <span><i>{{$t("index.airborne")}}</i></span>
            <div
              style="
                position: absolute;
                bottom: 10px;
                text-align: center;
                color: rgb(255, 255, 255);
                width: 100%;
                font-size: 12px;
              "
            >
              {{ item.xuanfei_name }}
            </div>
          </div>
        </div>

        <div
        @click="toLogin('/Choose')"
          style="
            width: 23px;
            padding: 20px 5px 10px;
            height: 145px;
            margin-top: 30px;
            background: rgb(249, 249, 249);
            color: rgb(153, 153, 153);
            font-size: 12px;
          "
        >
          {{$t("concubine.ckgdsj")}}
        </div>
      </div>
    </div>
    <div style="padding: 10px;">
      <div style="font-size: 16px;">{{$t("index.recommend")}}</div>
      <div>
        <div class="horizontalItem" v-for="item in datalist" :key="item.id"  @click="toDetail(item.id)">
          <div  class="imgBox">
            <div
              
              class="van-image"
              style="
                width: 120px;
                height: 120px;
                overflow: hidden;
                border-radius: 8px;
              "
            >
              <img
                :src="item.img_url"
                class="van-image__img"
                style="object-fit: cover;"
              />
            </div>
            <span ><i>{{$t("concubine.high_end")}}</i></span>
          </div>
          <div  style="width: calc(100% - 140px);">
            <div  class="top">
              <div >
                <div  class="van-image" style="width: 13px;">
                  <img
                    src="/img/vip.png"
                    style="width: 13px; height: 13px;"
                    class="van-image__img"
                  />
                </div>
                <span >{{$t("index.authentication")}}</span>
              </div>
              <div >
                <div  class="van-image" style="width: 13px;">
                  <img
                    src="/img/vip.png"
                    class="van-image__img"
                    style="width: 13px; height: 13px;"
                  />
                </div>
                <span >{{$t("index.video_authentication")}}</span>
              </div>
              <div
                
                style="
                  width: 70px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
               {{ item.xuanfei_name }}
              </div>
            </div>
            <div  class="tags">
              <van-tag
        v-for="(tag, index) in item.flagArray"
        :key="index"
        :type="tagTypes[index]"
      >
        {{ tag }}
      </van-tag>
              
            </div>
            <!----><!---->
            <div  style="display: flex; align-items: center;">
              <span
                
                style="font-size: 14px; color: rgb(102, 102, 102);"
                >{{$t("index.toll")}}：</span
              >
              <van-rate v-model="item.number"   color="#ffd21e" size="20px" />
            </div>
            <div
              
              style="
                color: rgb(153, 153, 153);
                font-size: 12px;
                margin: 5px 0px;
              "
            >
            {{$t("index.height")}}: {{item.hight}}cm {{$t("index.bust")}}：{{ item.bust }} {{$t("index.address")}}： {{$t("index.National")}}：{{$t("index.yes")}}
            </div>
            <div  class="yueta">{{$t("index.about")}}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        background: linear-gradient(rgb(254, 89, 134), rgb(255, 132, 139));
        width: 96%;
        margin: 0px auto 30px;
        border-radius: 8px;
        overflow: hidden;
        padding-bottom: 10px;
      "
    >
      <div
        style="
          color: rgb(255, 232, 105);
          text-align: center;
          padding: 10px 0px;
        "
      >
        當前 1590 人在線匹配
      </div>
      <div class="OnlineMatching">
        <!---->
        <!---->
        <div
          :style="{
            transform: `translate(0px, ${translateY}px)`,
            transition: 'transform 1s ease-in',
          }"
          ref="scrollContainer"
        >
          <div style="overflow: hidden;">
            <ul class="item">
              <li>ちざきまきこ 約炮賬號入會成功</li>
              <li>しらつちのりこ 約炮賬號入會成功</li>
              <li>はくやすのり 約炮賬號入會成功</li>
              <li>はくやすのり 約炮賬號入會成功</li>
              <li>ふちれいこ 約炮賬號入會成功</li>
              <li>ちのなな 約炮賬號入會成功</li>
              <li>ちのなな 約炮賬號入會成功</li>
              <li>たけじかずのぶ 約炮賬號入會成功</li>
              <li>たけじかずのぶ 約炮賬號入會成功</li>
              <li>ふちれいこ 約炮賬號入會成功</li>
            </ul>
          </div>
          <div style="overflow: hidden;">
            <ul class="item">
              <li>ちざきまきこ 約炮賬號入會成功</li>
              <li>しらつちのりこ 約炮賬號入會成功</li>
              <li>はくやすのり 約炮賬號入會成功</li>
              <li>はくやすのり 約炮賬號入會成功</li>
              <li>ふちれいこ 約炮賬號入會成功</li>
              <li>ちのなな 約炮賬號入會成功</li>
              <li>ちのなな 約炮賬號入會成功</li>
              <li>たけじかずのぶ 約炮賬號入會成功</li>
              <li>たけじかずのぶ 約炮賬號入會成功</li>
              <li>ふちれいこ 約炮賬號入會成功</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div style="padding-bottom: 55px;"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notice: this.$t("index.loading"),
      translateY: -18,
      containerHeight: 0,
      hasToken: false,
      basicData: [],
      datalist: [],
      groupedData: [],
      banners: [{}],
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed:800,
        autoplay:true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows : true
        }
      },
      tagTypes: ['primary', 'success', 'danger', 'warning']
    };
  },
  methods: {
    getBasicConfig() {
      this.$http({
        method: "get",
        url: "sys_config",
      }).then((res) => {
        this.basicData = res.data;
        console.log(this.basicData);
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        // this.getGameItem();//获取首页游戏列表
        // this.getMovieList_0(this.basicData);//获取首页视频0
        // this.getMovieList_1(this.basicData);//获取首页视频1
      });
    },
    getHotLottery() {
      this.$http({
        method: "get",
        url: "lottery_hot",
      }).then((res) => {
      console.log(res,11)
      });
    },
    getBanner(data){
      this.banners = data.banners;
    },
    toLogin(str) {
      this.$router.push({ path: str});
    },
    getNotice(data){
      this.notice = data.notice;
    },
    startAnimation() {
      const interval = setInterval(() => {
        this.translateY -= 18;

        if (this.translateY <= -this.containerHeight) {
          this.translateY = 0;
        }
      }, 1000);

      // 可选：在组件销毁时清除定时器
      this.$once("hook:beforeDestroy", () => {
        clearInterval(interval);
      });
    },
    toDetail(id) {
      this.$router.push({ path: "/detail", query: { id: id } });
    },
    checkToken() {
      const token = localStorage.getItem("token");
      console.log(token);
      this.hasToken = !!token; // 如果 token 存在，则 hasToken 为 true，否则为 false
      console.log(this.hasToken);
      this.$nextTick(() => {
        console.log("View updated:", this.hasToken);
      });
    },
    getxuanfeilist() {
      this.$http({
        method: "get",
        url: "xuanfeilist",
        data: { id: 1, page: 1 },
      }).then((res) => {
        const lanng = localStorage.getItem("lang");
         console.log(lanng)
        this.datalist = res.data;
        this.datalist.forEach(item => {
          const parts = item.flag.split('|')
          lanng == 'zh'? item.flag = parts[0]:lanng == 'en'? item.flag = parts[1]: lanng == 'ja'?item.flag = parts[2]:item.flag = parts[3]
          item.flagArray = item.flag.split('，');
         
        })
        const chunkSize = 2;
        this.groupedData = this.datalist.reduce((acc, item, index) => {
          if (index % chunkSize === 0) acc.push([]);
          acc[Math.floor(index / chunkSize)].push(item);
          return acc;
        }, []);
        console.log(this.groupedData);
        console.log(res.data);
      });
    },
  },

  mounted() {
    this.containerHeight = this.$refs.scrollContainer.clientHeight;
    this.startAnimation();
  },
  created() {
    this.checkToken();
    this.getBasicConfig();
    this.getxuanfeilist();
    this.getHotLottery()
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 13vw;
  background: linear-gradient(30deg, #7d76ef, #d63f8c);
}
.header_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 40px;
  color: #fff;
  .img {
    height: 80px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.notice-swiper {
  height: 11vw;
  font-size: 4vw;
  border-radius: 7vw;
}
.sub {
  background: rgb(63, 58, 91);
  padding: 8px;
  color: rgb(235, 202, 175);
  font-size: 24px;
  border-radius: 16px 0;
  margin-left: 4px;
}
.gdgride {
  padding-right: 40px;
}
.gdgride div {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}
.gdgride span {
  position: absolute;
  z-index: 9;
  background-color: #ebcaaf;
  color: #8d684b;
  transform: rotate(45deg);
  width: 20vw;
  height: 20vw;
  font-size: 3vw;
  top: -11vw;
  right: -11vw;
}
.gdgride span i {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
}
.van-image {
  position: relative;
  display: inline-block;
}
.horizontalItem {
  display: flex;
  border-bottom: 2px solid #eee;
  padding: 40px 0 4vw;
}
.horizontalItem .imgBox {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  margin-right: 40px;
}
.horizontalItem .imgBox span {
  position: absolute;
  z-index: 9;
  background-color: #ebcaaf;
  color: #8d684b;
  transform: rotate(45deg);
  width: 20vw;
  height: 20vw;
  top: -11vw;
  right: -11vw;
}
.horizontalItem .imgBox i {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
  font-size: 4vw;
}
.top {
  display: flex;
}
.horizontalItem .top div:first-child,
.horizontalItem .top div :nth-child(2) {
  background: linear-gradient(180deg, #e7caaf, #fff7ed);
  color: #a4826b;
  font-weight: 600;
  font-size: 3vw;
  padding: 3px;
  margin-right: 10px;
}
.horizontalItem .top div:first-child,
.horizontalItem .top div:nth-child(2) {
  background: linear-gradient(180deg, #e7caaf, #fff7ed);
  color: #a4826b;
  font-weight: 600;
  font-size: 3vw;
  padding: 3px;
  margin-right: 10px;
}
.horizontalItem .tags {
  display: flex;
  margin: 10px 0 10px;
  flex-wrap: wrap;
}
.horizontalItem .tags .van-tag {
  padding: 15px;
  font-size: 3vw;
  margin: 0 10px 10px 0;
  border-radius: 16px;
}
.horizontalItem .yueta {
  background: linear-gradient(90deg, #df35ad, #4f1db5);
  color: #fff;
  border-radius: 16px;
  padding: 20px 0;
  text-align: center;
  font-size: 3vw;
}
.OnlineMatching {
  height: 28vw;
  overflow: hidden;
}

.OnlineMatching ul li {
  text-align: center;
  color: #fff;
  font-size: 4vw;
  margin-top: 20px;
}
</style>
